import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { isPartagetapassionCom } from 'common-front/src/thirdPartyDomains';
import { THIRD_PARTY_DOMAINS } from 'common/src/thirdPartyDomains';
import * as React from 'react';
import { AsoAuth } from './aso/asoAuth';
import { Auth } from './auth';

interface IAuthRouterProps {
    loadDelegationsSpace: boolean;
    loadMembersSpace: boolean;
}

export const AuthRouter = (props: IAuthRouterProps) => {
    const {
        history,
        params: { organizationId }
    } = useHeavent();

    if (isPartagetapassionCom(organizationId)) {
        return <AsoAuth />;
    } else if (THIRD_PARTY_DOMAINS.includes(location.host)) {
        // 404 because event space for third parties is provided by them
        history.replace('/');

        return null;
    } else {
        return (
            <Auth
                loadDelegationsSpace={props.loadDelegationsSpace}
                loadMembersSpace={props.loadMembersSpace}
            />
        );
    }
};
