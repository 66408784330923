import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Item } from 'common-front/src/designSystem/components/leftMenu/item';
import { LeftMenu } from 'common-front/src/designSystem/components/leftMenu/leftMenu';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    FormId,
    FormMemberRegistrationFragment,
    MemberRegistrationUserInfoFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IUpdateFormSidebarProps {
    forms: FormMemberRegistrationFragment[];
    title: string;
    userInfo: MemberRegistrationUserInfoFragment;

    getEditPath(formId: FormId): string;
}

export const UpdateFormSidebar = ({
    forms,
    getEditPath,
    title,
    userInfo
}: IUpdateFormSidebarProps) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();

    return (
        <Flex css={{ flex: '1' }} direction="column" gap="6">
            <BackButton
                color="primary"
                returnPathFallback={MembersPaths.MEMBER_REGISTRATION({
                    organizationId,
                    eventId,
                    userInfoId
                })}
            >
                {translate('retour_92202')}
            </BackButton>

            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$6'
                }}
                direction="column"
                gap="3"
            >
                <Avatar
                    email={userInfo.email}
                    image={userInfo.picture?.url}
                    name={userInfo.name}
                    size={64}
                />

                <Box>
                    <Box color="gray500" fontSize="textXs" fontWeight={'medium'}>
                        {title}
                    </Box>

                    <Box color="gray800" fontSize="textXl" fontWeight="semiBold">
                        {userInfo.name}
                    </Box>
                </Box>

                <Separator direction="horizontal" />

                <LeftMenu>
                    {isNonEmptyArray(forms) ? (
                        sortBy(forms, 'name').map((form) => (
                            <Item key={form.id} to={getEditPath(form.id)}>
                                {form.name}
                            </Item>
                        ))
                    ) : (
                        <Item to={getEditPath(-1 as FormId)}>
                            {translate('aucun_formulair_41857')}
                        </Item>
                    )}
                </LeftMenu>
            </Flex>
        </Flex>
    );
};
