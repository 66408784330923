import { Empty } from 'common-front/src/components/empty/empty';
import { Form } from 'common-front/src/components/form/form';
import { UserCreateForm } from 'common-front/src/components/users/create/userCreateForm';
import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useUserInfoUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FieldType,
    FormElementType,
    FormId,
    FormMemberRegistrationFragment,
    MemberRegistrationQuery
} from 'common/src/generated/types';
import {
    FieldFormElement,
    IUpdateUserInfoValues,
    UserInfoInputService
} from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UpdateForm } from '../../../../common/updateForm';

const TEXT_FIELD_TYPES = [FieldType.Address, FieldType.Text, FieldType.Textarea, FieldType.Time];

interface IMemberRegistrationUpdateInformationProps {
    customFields: MemberRegistrationQuery['organization']['customFields']['nodes'];
    country?: string;
    userInfo: MemberRegistrationQuery['organization']['userInfo'];

    reload(): void;
}

export const MemberRegistrationUpdateInformation = ({
    customFields,
    country,
    reload,
    userInfo
}: IMemberRegistrationUpdateInformationProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const userInfoInput = useService(UserInfoInputService);

    const { mutate: userInfoUpdate, loader: mutationLoader } = useUserInfoUpdateMutation();

    const forms = React.useMemo(() => {
        const fuis = userInfo.formsUsersInfos.filter((fui) =>
            eventId ? fui.eventId !== null : fui.eventId === null
        );

        if (isNonEmptyArray(fuis)) {
            return fuis.flatMap((fui) => (fui.form.elements.length > 0 ? [fui.form] : []));
        } else {
            return [];
        }
    }, [userInfo.formsUsersInfos, eventId]);
    const editableForms = React.useMemo(
        () => forms.filter((form) => form.isEditableInMemberSpace),
        [forms]
    );

    return (
        <Switch>
            <Route
                path={MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT_FORM(
                    ':organizationId',
                    ':userInfoId',
                    eventId ? ':eventId' : null,
                    ':formId'
                )}
            >
                <UpdateForm
                    child={(currForm: FormMemberRegistrationFragment) => {
                        const fieldFormElements = currForm.elements.filter(
                            (e) => e.elementType === FormElementType.Field
                        ) as FieldFormElement[];

                        return (
                            <Form
                                direction="column"
                                initialValues={{
                                    filledFormsIds: [],
                                    formKeyToTeamCode: {},
                                    userInfo: userInfoInput.userInfoUpdateInputDefault(
                                        userInfo,
                                        customFields,
                                        country
                                    )
                                }}
                                render={({ form, handleSubmit, values }) => (
                                    <Flex direction="column" gap="5">
                                        <Box>
                                            <UserCreateForm
                                                change={form.change}
                                                elements={currForm.elements}
                                                organizationId={organizationId}
                                                prefix="userInfo.fields."
                                                renderSection={React.useCallback(
                                                    (section: string, index: number) => (
                                                        <>
                                                            {index !== 0 && <Spacer height="7" />}

                                                            <Box font="gray900 textLg medium">
                                                                {section}
                                                            </Box>

                                                            <Spacer height="4" />

                                                            <Separator direction="horizontal" />
                                                        </>
                                                    ),
                                                    []
                                                )}
                                                values={values.userInfo.fields}
                                            />
                                        </Box>

                                        <Separator direction="horizontal" />

                                        <Button
                                            color="primary"
                                            textAlign="center"
                                            onClick={handleSubmit}
                                        >
                                            {mutationLoader || translate('update')}
                                        </Button>
                                    </Flex>
                                )}
                                validate={validateService.validateForForm(
                                    userInfoInput.updateUserInfoSchema(fieldFormElements)
                                )}
                                width={1}
                                onSubmit={async (values: IUpdateUserInfoValues) => {
                                    const fields = values.userInfo.fields;

                                    // special case for deletion, instead of undefined set it to '' or null
                                    // this way it will really be deleted
                                    customFields.forEach((customField) => {
                                        if (
                                            TEXT_FIELD_TYPES.includes(customField.fieldType) &&
                                            !fields[customField.slug]
                                        ) {
                                            fields[customField.slug] = '';
                                        } else if (
                                            customField.fieldType === FieldType.File &&
                                            !fields[customField.slug]
                                        ) {
                                            fields[customField.slug] = null;
                                        }
                                    });

                                    await userInfoUpdate({
                                        organizationId: organizationId,
                                        eventId: eventId,
                                        userId: userInfo.userId,
                                        userInfo: {
                                            id: userInfo.id,
                                            fields: values.userInfo.fields
                                        }
                                    });

                                    reload();
                                }}
                            />
                        );
                    }}
                    forms={editableForms}
                    getEditPath={(formId) =>
                        MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT_FORM(
                            organizationId,
                            userInfoId,
                            eventId,
                            formId
                        )
                    }
                    returnPathFallback={MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                        organizationId,
                        userInfoId,
                        eventId
                    )}
                    title={translate('mise_jour_des_00596')}
                    userInfo={userInfo}
                />
            </Route>

            <Route
                exact
                path={MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT(
                    ':organizationId',
                    ':userInfoId',
                    eventId ? ':eventId' : null
                )}
            >
                <Empty
                    path={MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT_FORM(
                        organizationId,
                        userInfoId,
                        eventId,
                        isNonEmptyArray(editableForms) ? editableForms[0].id : (-1 as FormId)
                    )}
                    replace={true}
                />
            </Route>
        </Switch>
    );
};
