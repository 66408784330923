import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { DelegationSpaceDashboardQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { getFormHref } from 'common/src/util/url';
import * as React from 'react';

interface IDelegationDetailsCardProps {
    delegation: DelegationSpaceDashboardQuery['organization']['delegation'];
}

export const DelegationDetailsCard = ({ delegation }: IDelegationDetailsCardProps) => {
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);
    const translate = useTranslate();

    const formLink = React.useMemo(
        () =>
            getFormHref({
                organizationId: delegation.organizationId,
                eventId: delegation.eventId,
                delegationId: delegation.id,
                formId: delegation.form.id
            }),
        [delegation]
    );

    return (
        <Flex direction="column" gap="4" width={1}>
            {delegation.deadlineDate && (
                <>
                    <Flex align="center" gap="3" width="100%">
                        <Box font="gray800 textSm medium">
                            {translate('date_butoir_dinscription')}
                        </Box>

                        <Box css={{ flex: '1' }} />

                        <Box>
                            {dateTimeService.toLocaleString(
                                delegation.deadlineDate,
                                LocaleFormats.DateOnly.MonthShort
                            )}
                        </Box>
                    </Flex>

                    <Separator direction="horizontal" />
                </>
            )}

            <Flex direction="column" gap="3">
                {delegation.showFormLinkInDelegationSpace && (
                    <>
                        <Flex align="center" gap="3" justify="between">
                            <Box font="gray800 textSm medium">{translate('Form')}</Box>

                            <LinkText css={{ color: '$primary700' }} to={formLink}>
                                {delegation.form.name}
                            </LinkText>
                        </Flex>
                        <Separator direction="horizontal" />
                    </>
                )}

                <Flex direction="column" gap="1" width="100%">
                    <Flex align="center" gap="3" justify="between">
                        <Box font="gray800 textSm medium">{translate('nombre_de_membr_08851')}</Box>

                        <Box>
                            {translate(
                                '_1_2_membres_04485',
                                delegation.numberOfResources,
                                numberService.toNumberOrInfinity(delegation.maxResources)
                            )}
                        </Box>
                    </Flex>

                    {delegation.maxResources && (
                        <ProgressBar
                            isErrorIfOver={true}
                            percent={delegation.numberOfResources / delegation.maxResources}
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export const DelegationDetailsCardSkeleton = () => (
    <Flex direction="column" gap="4" width={1}>
        <Skeleton borderRadius="$2" height={40} width={1} />
        <Separator direction="horizontal" />
        <Skeleton borderRadius="$2" height={40} width={1} />
    </Flex>
);
